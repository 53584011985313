import styled from "styled-components";
import tw from "tailwind.macro";

export const Skills = styled.div`
  ${tw`flex flex-wrap w-full`};
`;

export const Skill = styled.div`
  ${tw`w-full sm:w-1/2`};
`;
